// 定义mutation类型常量
export const MUTATION_TYPES = {
  UPDATE_DATA: 'UPDATE_DATA' 
}

const state = {
  // down模块的状态
  updateData: null
}

const mutations = {
  // down模块的mutation
  [MUTATION_TYPES.UPDATE_DATA](state, payload) {
    state.updateData = payload;
  }
}

const actions = {  
  // down模块的action
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}