import { getFingerprint } from '@/utils/fingerprint'

const state = {
  fingerprint: '',
  deviceInfo: {}
}

const mutations = {
  SET_FINGERPRINT(state, fingerprint) {
    state.fingerprint = fingerprint
  },
  SET_DEVICE_INFO(state, info) {
    state.deviceInfo = info
  }
}

const actions = {
  async initDeviceFingerprint({ commit }) {
    try {
      const fingerprint = await getFingerprint()
      commit('SET_FINGERPRINT', fingerprint)
      return fingerprint
    } catch (error) {
      console.error('获取设备指纹失败:', error)
      return null
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}