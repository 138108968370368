import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/components/HomeLayout.vue')
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('@/views/home/components/HelpSection.vue')
  // },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/cart/cartIndex.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/loginIndex.vue')
  },
  {
    path: '/orderIndex',
    name: 'OrderIndex',
    component: () => import('@/views/order/orderIndex.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/register/helpIndex.vue')
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/products/proIndex.vue')
  },
  {
    path: '/helpDetail',
    name: 'HelpDetail',
    component: () => import('@/views/helpDetail/helpDetailIndex.vue')
  },
  {
    path: '/explain',
    name: 'Explain',
    component: () => import('@/views/explain/explainIndex.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/searchIndex.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/views/payment/paymentIndexLayout.vue')
  },
  {
    path: '/down',
    name: 'Down',
    component: () => import('@/views/down/downLayout.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about/aboutLayout.vue')
  },
  {
    path: '/guide',
    name: 'Guide',
    component: () => import('@/views/guide/guideLayout.vue')
  },
  {
    path: '/worker',
    name: 'Worker',
    component: () => import('@/views/workerOrder/workerOrderIndexLayout.vue')
  },
  {
    path: '/media',
    name: 'Media',
    component: () => import('@/views/mediaReport/mediaReportIndexLayout.vue')
  },
  {
    path: '/center',
    name: 'Center',
    component: () => import('@/views/helpCenter/helpCenterIndexLayout.vue')
  },
  {
    path: '/centerDetail',
    name: 'CenterDetail',
    component: () => import('@/views/helpCenter/helpCenterDetail.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/productDetail/productDetailIndexLayout.vue')
  },
  {
    path: '/currency',
    name: 'Currency',
    component: () => import('@/views/currency/currencyIndexLayout.vue')
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: () => import('@/views/announcements/announcementsIndexlayout.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('@/views/detail/detailIndexLayout.vue')
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/result/resultIndexLayout.vue')
  },
  {
    path: '/regulations',
    name: 'Regulations',
    component: () => import('@/views/regulations/regulationsIndexLayout.vue')
  },
  {
    path: '/regulationsDetail',
    name: 'RegulationsDetail',
    component: () => import('@/views/regulationsDetail/regulationsDetailIndexLayout.vue')
  },
  // {
  //   path: '/shop',
  //   name: 'Shop',
  //   component: () => import('@/views/shop/shopIndex.vue')
  // },
  {
    path: '/dealer',
    name: 'Dealer',
    component: () => import('@/views/dealer/dealerIndexLayout.vue')
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('@/views/helpCenter/helpList.vue')
  },
  {
    path: '/orderDetail',
    name: 'OrderDetail',
    component: () => import('@/views/payment/payDetailLayout.vue')
  },
  {
    path: '/track',
    name: 'Track',
    component: () => import('@/views/track/trackIndexLayout.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

export default router
