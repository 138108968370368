import { render, staticRenderFns } from "./navitor.vue?vue&type=template&id=283a52d0&scoped=true"
import script from "./navitor.vue?vue&type=script&lang=js"
export * from "./navitor.vue?vue&type=script&lang=js"
import style0 from "./navitor.vue?vue&type=style&index=0&id=283a52d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283a52d0",
  null
  
)

export default component.exports