const state = {
  currentLanguage: {
    code: localStorage.getItem('language') || 'tw',
    name: localStorage.getItem('languageName') || '繁體中文'
  }
}

const mutations = {
  SET_LANGUAGE(state, payload) {
    state.currentLanguage = payload
  }
}

const actions = {
  updateLanguage({ commit }, payload) {
    commit('SET_LANGUAGE', payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}