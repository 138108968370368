import Vue from 'vue'
import Vuex from 'vuex'
import down from './modules/down' // 引入down模块
import language from './modules/footer' // 引入down模块
import languages from './modules/language'
import device from './modules/device'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    down, // 注册down子模块  
    language,
    languages,
    device
  }
})
