<template>
  <div
    class="lt-breadcrumbs-wrapper lt-mb-8 lt-container lt-d-print-none"
    style="margin-top: 20px;margin-bottom: 3.5rem !important;"
  >
    <div class="lt-container-inner">
      <ol class="breadcrumbs">
        <li v-for="(item,index) in categoryList" :key="index" @click="navClick(item)">
          <a>{{ item.name }}</a>
        </li>

        <!-- <li>
          <a href="/hc/zh-cn/categories/4848376946063-OneKey-App">OneKey App</a>
        </li>

        <li>
          <a href="/hc/zh-cn/sections/8442135438991-%E5%BC%80%E5%A7%8B%E4%BD%BF%E7%94%A8">开始使用</a>
        </li>-->
      </ol>
    </div>
  </div>
</template>
<script>
import { detailCategory } from "@/api/payment";
export default {
  name: "navitorIndex",
  props: {
    naId: {
      type: String,
      default: "0"
    },
    code:{
      type: String,
      default: "cn"
    }
  },
  data() {
    return {
      categoryList: []
      //   language: localStorage.getItem("language") || "cn"
    };
  },
  watch: {
    naId(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getDetailCategory();
      }
    },
    code(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getDetailCategory();
      }
    }
  },
  methods: {
    getDetailCategory() {
      detailCategory({
        id: this.naId,
        language:this.code
      }).then(res => {
        if (res.length > 0) {
          this.categoryList = res;
          this.categoryList.unshift({ name: "OneKey", id: "" });
        }
      });
    },
    navClick(item) {
      if (item.id) {
        this.$router.push({
          path: "/explain",
          query: {
            id: item.id,
            name: item.name
            //   description: item.description
          }
        });
      } else {
        this.$router.push({
          path: "/register"
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.breadcrumbs > li {
  display: inline-block;
  cursor: pointer;
}

[dir="ltr"] .breadcrumbs {
  padding-left: 0;
}

.breadcrumbs > li + li:before {
  padding: 0 5px;
  color: var(--breadcrumbs-color, var(--color-text-secondary));
  content: "› ";
  font-size: 22px;
}

.breadcrumbs > li a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.lt-container-inner {
  max-width: 1320px;
  margin: 0 auto;
}
</style>