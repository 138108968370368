<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getWebsiteTitle } from '@/api/payment'
import { mapState } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState('language', ['currentLanguage'])
  },
  watch: {
    currentLanguage: {
      handler(newLang) {
        this.$i18n.locale = newLang.code || 'tw'
        this.getWebsiteTitleData()
      },
      immediate: true
    }
  },
  created() {
    this.getWebsiteTitleData()
    // 初始化语言
    const savedLang = localStorage.getItem('language') || 'tw'
    this.$i18n.locale = savedLang
  },
  methods: {
    getWebsiteTitleData() {
      getWebsiteTitle().then(res => {
        document.title = res.name
      })
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'MiSans TC';
  src: url('@/assets/MiSans TC/ttf/MisansTC-Bold.ttf') format('truetype');
  font-weight: 700;  
}
@font-face {
  font-family: 'MiSans TC';
  src: url('@/assets/MiSans TC/ttf/MisansTC-Demibold.ttf') format('truetype');
  font-weight: 600;  
}
@font-face {
  font-family: 'MiSans TC';
  src: url('@/assets/MiSans TC/ttf/MisansTC-Medium.ttf') format('truetype');
  font-weight: 500; 
}
@font-face {
  font-family: 'MiSans TC';
  src: url('@/assets/MiSans TC/ttf/MisansTC-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'MiSans TC';
  src: url('@/assets/MiSans TC/ttf/MisansTC-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'MiSans TC';
  src: url('@/assets/MiSans TC/ttf/MisansTC-Normal.ttf') format('truetype');
  font-weight: normal;
}




@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/pingfang/PingFangSC-Light.ttf') format('truetype');
  font-weight: 300; 
}
@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/pingfang/PingFangSC-Medium.ttf') format('truetype');
  font-weight: 500;  
}
@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/pingfang/PingFangSC-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/pingfang/PingFangSC-Semibold.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/pingfang/PingFangSC-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'PingFang SC';
  src: url('@/assets/pingfang/PingFangSC-Ultralight.ttf') format('truetype');
}

@font-face {
  font-family: 'Nexa Bold';
  src: url('@/assets/NexaBold/Nexa Bold.otf') format('truetype');
 
}

#app {
  font-family: 'MiSans TC', 'PingFang SC' ,Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
