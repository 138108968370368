export default {
    infoDatasTitle: {
        "top_titele_one": "首页",
        "top_titele_two": "産品中心",
        "top_titele_three": "APP下载",
        "top_titele_four": "新手指南",
        "top_titele_five": "關於我們",
        "top_titele_six": "切换语言"
    },
    infoDataFoter: {
        "content1": "產品&服务",
        "content2": "硬件錢包",
        "content3": "APP 下載",
        "content4": "闪兑",
        "content5": "政策法規",
        "content6": "聯系我們",
        "content7": "關於我們",
        "content8": "使用條款",
        "content9": "公司介紹",
        "content10": "客服電話",
        "content11": "隱私政策",
        "content12": "服務協議",
        "content13": "客服郵箱",
        "content14": "商務合作",
        "content15": "媒體報道",
        "content16": "商務合作",
        "content61": "支持幣種",
        "content19": "經銷商",
        "content20": "訂單跟蹤",
       
    },
    infoDatas1: {
        // 首页
        "content1": "Digital Shield",
        "content2": "您加密貨幣的 ",
        "content3": "離線安全存儲錢包 ",
        "content4": "匯聚了業績頂尖的技術團隊 ",
        "content5": "為全球用戶提供安全、可靠的存储環境 ",
        "content6": "購買錢包",
        "content7": "觸屏易操作",
        "content8": "安全易管理",
        "content9": "無需聯網可轉賬",
        "content10": "聯名服務可定製",
        "content11": "屬於你的硬件錢包",
        "content12": "購買錢包",
        "content13": "内置最高等級",
        "content14": "CC EAL6+ 安全芯片",
        "content15": "100%開源",
        "content16": "可以隨時檢查代碼確保沒有後門或病毒等安全隱患",
        "content17": "多緯度加密技術",
        "content18": "創建助記詞",
        "content19": "蓝牙和加密二維碼",
        "content20": "兩種傳輸模式",
        "content21": "在確保安全的同時又實現了操作的方便快捷",
        "content22": "亮點展示",
        "content23": "支持綁定同協議錢包 比如小狐狸、imtoken、TP等",
        "content24": "支持隔空簽名,無需聯網即可完成兩兩掃碼轉賬最大程度的實現了交易的安全性舆便捷性",
        "content25": "支持市場上大多数主流幣，代幣3000+",
        "content26": "BTC、ETC、LTC、SOL......",
        "content27": "接收&送",
        "content28": "簡潔的界麵設計和流暢的體驗，轻鬆收髮加密資産查看交易動向和資産餘額。",
        "content29": "購買&兌换",
        "content30": "可藉用助記卡或信用卡購買加密資産，並支持一鍵閃兌有效保障強势行情交易·",
        "content31": "多鏈存儲",
        "content32": "支持BTC、ETH、BNB、TRX、DOT等多主鏈存儲可輕鬆使用和訪問鏈上代幣。",
        "content33": "開放的DApp瀏覽器",
        "content34": "支持 ETH,TRON 等DApp，輸入DApp網址 可體驗任意DApp",
        "content35": "聽聽用户的聲音",
        "content36": "什麼是冷存儲?",
        "content37": "冷存儲是指以永久離線的形式存储数字论產，",
        "content38": "物理隔離網絡，規避黑客攻擊風險。",
        "content39": "更多常見問题 ",
        "content40": "安全存儲，從盾安錢包開始",
        "content41": "購買錢包",
        "content42": "確保沒有後門或病毒等安全隱患",
        "content43": "又實現了操作的方便快捷",
        "content44": " 極致保護私鑰安全",
    },
    carousel: {
        items: [
            {
                title: "什麼是冷存儲?",
                description: "冷存儲是指以永久離線的形式存儲數字資產，物理隔離網絡，規避黑客攻擊風險。",
                image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/lunbo.png"
            },
            {
                title: "什麽是硬件錢包？",
                description: "硬件錢包是一種加密貨幣錢包,可以將私鑰和易於破解的計算機或智能手機之間提供完全隔離。 ",
                image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/lb2.png"
            },
            {
                title: "為什麽要用硬件錢包？ ",
                description: "使用硬件錢包可以在保護隱私條件下，100%保護加密資產安全，黑客將無法通過互聯網竊取或攻擊資產。",
                image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/lb3.png"
            }
        ]
    },
    carouselMobile: {
        items: [
            {
                title: "什麼是冷存儲?",
                description: "冷存儲是指以永久離線的形式存儲數字資產，物理隔離網絡，規避黑客攻擊風險。",
                image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/lb1.png"
            },
            {
                title: "什麽是硬件錢包？",
                description: "硬件錢包是一種加密貨幣錢包,可以將私鑰和易於破解的計算機或智能手機之間提供完全隔離。",
                image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/lb2.png"
            },
            {
                title: "為什麽要用硬件錢包？",
                description: "使用硬件錢包可以在保護隱私條件下，100%保護加密資產安全，黑客將無法通過互聯網竊取或攻擊資產。",
                image: "https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/mobile/lb3.png"
            }
        ]
    },
    infoDataProduct: {
        // 产品中心
        "content1": "Hold Your Own Crypto",
        "content2": "創新驅動 安全至上",
        "content3": "購買價格",
        "content4": "安全，無懈可擊",
        "content5": "100%開源",
        "content6": "用戶可以隨時檢查代碼，",
        "content7": "確保沒有後門或病毒等安全隱患。",
        "content8": "内置最高等级",
        "content9": "CC EAL6+",
        "content10": "安全芯片",
        "content11": "多緯度加密技術",
        "content12": "創建助記詞，",
        "content13": "極致保護私鑰安全。",
        "content14": "为加密资产保驾护航。",
        "content15": "創新，超越極限",
        "content16": "支持隔空簽名，無需聯網即可完成兩兩掃碼轉賬 最大程度的實現了交易的安全性與便捷性。",
        "content17": "硬件錢包可以綁定除Digital Shield 錢包外的其他同協議錢包，比如小狐狸、imtoken、TP等。",
        "content18": "體驗，無與倫比",
        "content19": "光滑精致的外殼，清晰明亮的觸摸屏，在確保出色手感的同時又可以享受",
        "content20": "極致的視覺體驗",
        "content21": "采用了指紋解鎖功能",
        "content22": "拇指輕觸，即可解鎖，即安全又便利",
        "content23": "簡單直觀",
        "content24": "設計頁面，一目了然，操作起來更舒適，更便捷新手也可以輕而易舉",
        "content25": "超長的電池容量",
        "content26": "充一次電可以用一個月",
        "content27": "支持多鏈幣種",
        "content28": "支持NFT",
        "content29": "Digital Shield與其他硬件錢包對比",
        "content30": "品牌",
        "content31": "Digital Shield ",
        "content32": "Ledger ",
        "content33": "Trezor ",
        "content34": "KeepKey",
        "content35": "安全芯片等級",
        "content36": "CC EAL6+ (最高等級) ",
        "content37": "CC EAL5+ ",
        "content38": "無專用安全芯片",
        "content39": "無專用安全芯片",
        "content40": "開源程度 ",
        "content41": "100%開源 (代碼透明可查)",
        "content42": " 部分開源 ",
        "content43": "部分開源",
        "content44": " 閉源",
        "content45": "傳輸方式 ",
        "content46": "藍牙+加密二維碼",
        "content47": "藍牙+USB",
        "content48": " USB ",
        "content49": "USB",
        "content50": "離線簽名\r\n",
        "create_time": "2025-03-12 14:26:41",
        "content_51": "支持隔空簽名\r\n(無網環境可用)\r\n",
        "content52": "需連接設備\r\n",
        "content53": "需連接設備\r\n",
        "content54": "需連接設備",
        "content55": "支持幣種\r\n",
        "content56": "3000+代幣\r\n覆蓋主流鏈與DApp\r\n",
        "content57": "1800+代幣\r\n",
        "content58": "1200+代幣\r\n",
        "content59": "100+代幣",
        "content60": "",
        "language_name": "繁体",
        "content61": null,
        "content62": "僅冷存儲\r\n",
        "content63": "僅冷存儲\r\n",
        "content64": "僅冷存儲",
        "content65": "礦工費優化\r\n",
        "content66": "智能算法\r\n自動匹配最像費率\r\n",
        "content67": "手動調整\r\n",
        "content68": "手動調整\r\n",
        "content69": "不支持",
        "content70": "脫離硬件獨立使用App\r\n",
        "content71": "支持\r\n",
        "content72": "不支持\r\n",
        "content73": "不支持\r\n",
        "content74": "不支持\r\n",
        "content75": "綁定其他App使用\r\n",
        "content76": "支持\r\n(可綁定同協議app錢包使用)\r\n",
        "content77": "支持\r\n",
        "content78": "不支持\r\n",
        "content79": "不支持",
        "content80": "加入購物車",
        "content81": "支持加密貨幣支付",
        "content82": "其他商店",
        "content83": "官方商店（全球經銷商）",
        "content84": "官方商店 正品保證",
        "content85": "購物車",
        "content86": "運輸費用",
        "content87": "總計費用",
        "content88": "付款",
        "content89": "免費",
        "content90": "退款政策",
        "content91": "運輸政策",
        "content92": "隱私政策",
        "content93": "服務政策",
        "content94": "平邮，预计时间10-15天送达",
        "content95": "加急，预计时间5-7天送达",
    },
    productTable: {
        rows: [
            {
                feature: "安全芯片等級",
                digitalShield: "CC EAL6+ (最高等級)",
                ledger: "CC EAL5+",
                trezor: "無專用安全芯片",
                keepkey: "無專用安全芯片"
            },
            {
                feature: "開源程度",
                digitalShield: "100%開源 (代碼透明可查)",
                ledger: "部分開源",
                trezor: "部分開源",
                keepkey: "閉源"
            },
            {
                feature: "傳輸方式",
                digitalShield: "100%藍牙+加密二維碼",
                ledger: "藍牙+USB",
                trezor: "USB",
                keepkey: "USB"
            },
            {
                feature: "離線簽名",
                digitalShield: "支持隔空簽名 (無網環境可用)",
                ledger: "需連接設備",
                trezor: "需連接設備",
                keepkey: "需連接設備"
            },
            {
                feature: "支持幣種",
                digitalShield: "3000+代幣 覆蓋主流鏈與DApp",
                ledger: "1800+代幣",
                trezor: "1200+代幣",
                keepkey: "100+代幣"
            },
            {
                feature: "冷錢包功能",
                digitalShield: "冷錢包功能 冷熱分離 （物理隔離網絡）",
                ledger: "僅冷存儲",
                trezor: "僅冷存儲",
                keepkey: "僅冷存儲"
            },
            {
                feature: "礦工費優化",
                digitalShield: "智能算法 自動匹配最像費率",
                ledger: "手動調整",
                trezor: "手動調整",
                keepkey: "不支持"
            },
            {
                feature: "脫離硬件獨立使用App",
                digitalShield: "支持",
                ledger: "不支持",
                trezor: "不支持",
                keepkey: "不支持"
            },
            {
                feature: "綁定其他App使用",
                digitalShield: "支持 (可綁定同協議app錢包使用)",
                ledger: "支持",
                trezor: "不支持",
                keepkey: "不支持"
            }
            // ... 其他行数据
        ]
    },
    infoDatas3: {
        // app下载
        "content1": "下载",
        "content2": "Digital Shield",
        "content3": "無需註冊賬戶 |多鏈支持 |海量優質Dapp |多樣化生態服務",
        "content4": "一個錢包輕松管理多種公鏈資產",
        "content5": "Digital Shield Wallet支持BTC、ETH、BNB TRX、DOT等多個主鏈存储，可松使用和訪問 鏈上代幣3000+。",
        "content6": "輕松存储和管理NFT",
        "content7": "Digital Shield Wallet與Dapp無縫連接，安全地存儲和輕松管理所有 NFT。",
        "content8": "一站式數字資產解决方案",
        "content9": "Digital Shield Wallet集成了數字資產兌换和交易服務，使您可以輕松地兌换數字資產和代幣，並可借用助記卡或信用卡購買加密資產。",
        "content10": "離線創建賬戶",
        "content11": "Digital Shield Wallet可以在斷網的情況下創建賬戶，實 現了從物理上隔離網絡，杜絕黑客沖擊",
    },
    infoDataQuestion: {
        // 新手指南
        "content1": "HL,請問有什麽可以幫到您?",
        "content2": "使用關鍵詞捜索.",
        "content3": "捜索",
        "content4": "Digital Shield ",
        "content5": "操作指引，快速上手",
        "content6": "Digital Shield",
        "content7": "盾安热钱包,一站式服务",
        "content8": "常見問题",
        "content9": "99%的疑惑都能帮您解答",
        "content10": "提交工单",
        "content11": "如果你無法通遏搜索找到答 案，請随時向我們提交工單",
        "content12": "FAQ",
        "content13": "盾安硬件錢包",
        "content14": "安全和 隱私",
        "content15": "購買和交换",
        "content16": "盾安熱錢包",
        "content17": "交易和gas费用",
        "content18": "訂購和售後",
        "content19": "找不到您想要的?",
        "content20": "提交工单",
        "content21": "APP",
        "content22": "硬件钱包",
    },
    infoDataAbout: {
        // 关于我们
        "content19": "我們是誰?",
        "content20": "Digital Shield 錢包是一家依靠強大的技術團隊而成立的加密資產存儲安全服務平臺，隸屬於香港宇恒網絡科技有限公司。其技術團隊來自於國家重點軟件企業飛天誠信，其在網絡銀行安全交易、支付卡及服務、移動支付安全、雲認證、身份認證及軟件保護等多個領域擁有成熟的技術標準、完整的生態系統，以及全場景解決方案·曾給多家知名硬件錢包提供技術支持，在區塊鏈底層技術和加密資產安全研究等方面具有豐富的實踐經驗。旨在為全球加密市場提供更前沿、更安全、更便捷的數字資產存儲方案。",
        "content21": "我們是做什麽的 ?",
        "content22": "Digital Shield 錢包采用夯實的技術經驗和創新的科技理念，致力於為用戶提供一種既安全又便捷的數字資產管理方式，並通過硬件與軟件的有機結合，確保用戶能夠高效、安全地管理其加密資產。",
        "content23": "技術優勢",
        "content24": "100%開源",
        "content25": "多重傳輸方式",
        "content26": "(加密二維碼、藍牙)",
        "content27": "支持隔空簽名",
        "content28": "杜絕聯網隱患",
        "content29": "可綁定同恊議錢包",
        "content30": "采用高效算法",
        "content31": "確保最優礦工費",
        "content32": "私鑰安全",
        "content33": "采用多緯度加密技術創建助記詞",
        "content34": "極致保護私鑰安全;",
        "content35": "內置最高等級CC EAL6+安全芯片，",
        "content36": "為加密資產保駕護航;",
        "content37": "冷熟分離",
        "content38": "真正做到了物理隔離網絡",
        "content39": "操作便捷",
        "content40": "Simple Design",
        "content41": "產品設計簡潔明了 易於上手;",
        "content42": "Multi-chain",
        "content43": "支持多鏈資產管理,BTC、ETH等主鏈幣，支持代幣 上千種;",
        "content44": "Two modes",
        "content45": "支持藍牙和加密二維碼兩 種傳輸模式，確保安全的 同時操作方便快捷;",
        "content46": "One-stop service",
        "content47": "提供一站式數字資產管理服務，整合了存儲、兌換、交易、NFT管理等多種功能，無需頻繁切換平臺，即可輕 松管理所有加密資產。",
        "content48": "我們在哪",
        "content49": "我們的總部設中國香港，在俄羅斯、泰國、日本等設有分公司。",
        "content50": "俄羅斯\r\n",
        "create_time": "2025-03-12 15:38:48",
        "content_51": "日本\r\n",
        "content52": "泰國\r\n",
        "content53": "中國香港",
        "content54": "我們的使命",
        "content55": "全球頂級加密資產存儲服務商",
        "content56": "致力於成為全球最受歡迎的加密資產存儲管理品牌，通過不斷創新和優化，為用戶提供極致的數字資產管理體驗。打造一個安全、透明、公平的加密資產生熊環境，推動數字經濟的繁榮發展",
        "content57": "合作夥伴",
        "content58": "聯系我們",
        "content59": "客服郵箱:www.ds.pro@gmail.com",
        "content60": "商務合作:DigitalShield88@gmail.com",
        "language_name": "繁体",
        "content61": "支持幣種"
    },
    infoCenter: {
        "content1": "分類",
        "content2": "幫助中心",
        "content3": "返回",
    },
    infoEvent:{
        "content1": "限時免gas費",
        "content2": "為慶祝Digital Shield 熱錢包上線，",
        "content3": "平臺特推出", 
        "content4": "「TRC-20 USDT轉賬免gas費」", 
        "content5": "限時福利活動！", 
        "content6": "每個用戶每日可享", 
        "content7": "10筆全免！", 
        "content8": "了解活動詳情", 
    },
    infoPayment:{
        "content1": "收貨地址",
        "content2": "國家/地區",
        "content3": "請選擇國家地區", 
        "content4": "名", 
        "content5": "姓", 
        "content6": "詳細地址", 
        "content7": "請選擇城市", 
        "content8": "郵政編碼", 
        "content9": "聯系電話",
        "content10": "保存此信息以便下次使用",
        "content11": "支付方式",
        "content12": "小計",
        "content13": "配送費",
        "content14": "選完地址後可見",
        "content15": "總計",
        "content16": "查看地址",
        "content17": "剩余時間",
        "content18": "重要提示",
        "content19": "1.不要將任何非USDT資產轉移到此地址。",
        "content20": "網絡",
        "content21": "數量",
        "content22": "地址",
        "content23": "我已支付",
        "content24": "2.檢查資產的網絡是否為“Tron（TRC-20）”",
        "content25": "3.付款金額必須足夠，費用不計算在內",
        "content26": "4.粘貼後仔細檢查地址，以防剪貼板被劫持。",
    },
    infoPaymentDetail:{
        "content1": "非常感謝!",
        "content2": "您的訂單已確認",
        "content3": "訂單詳情", 
        "content4": "聯系方法", 
        "content5": "付款方式", 
        "content6": "收貨地址", 
        "content7": "賬單地址", 
        "content8": "送貨方式", 
        "content9": "小計",
        "content10": "保存此信息以便下次使用",
        "content11": "支付方式",
        "content12": "小計",
        "content13": "配送費",
        "content14": "包郵",
        "content15": "總計",
    },
    infoTrack:{
        "content1": "訂單號",
        "content2": "輸入訂單號",
        "content3": "聯系方式", 
        "content4": "輸入聯系方式", 
        "content5": "快遞單號", 
        "content6": "輸入快遞單號", 
        "content7": "查詢", 
        "content8": "免運費", 
        "content9": "所有超過壹定訂單金額都享受免費配送服務（有例外）",
        "content10": "安全支付",
        "content11": "支持信用卡、Paypal、GPay、Apple Pay、銀聯、支付寶和加密貨幣支付（使用 MePay 和 Solana Pay）",
        "content12": "客戶服務",
        "content13": "我們定期培訓團隊以提供最佳服務，及時回應和解決問題",
        "content14": "加入我們的社區",
        "content15": "關註我們的 IG、X、加入我們的通知頻道，獲取最新資訊",
        "content16": "已下單",
        "content17": "已發貨",
        "content18": "運送中",
        "content19": "派送中",
        "content20": "已完成",
        "content21": "狀態",
        "content22": "快遞單號",
        "content23": "訂單",
    },
}