<template>
  <div class="header-container" :style="{ background: isScrolled ? '#0a0d0d' : 'rgba(0, 0, 0, 0)' }">
    <div class="logo-container">
      <img class="logo-img" src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/name.png" alt="Logo"
        @click="goToHome" />
      <!-- <span class="logo-text">Digital Shield</span> -->
    </div>
    <el-menu v-if="show" :default-active="activeIndex" class="nav-menu" mode="horizontal" background-color="transparent"
      text-color="#fff" @select="handleSelect">
      <template>
        <el-menu-item style="margin: 0 1.25rem;    font-family: MiSans TC;
    font-weight: 500;" index="1">{{ $t('infoDatasTitle.top_titele_one') }}</el-menu-item>
        <el-menu-item style="margin: 0 1.25rem;    font-family: MiSans TC;
    font-weight: 500;" index="2">{{ $t('infoDatasTitle.top_titele_two') }}</el-menu-item>
        <el-menu-item style="margin: 0 1.25rem;    font-family: MiSans TC;
    font-weight: 500;" index="3">{{ $t('infoDatasTitle.top_titele_three') }}</el-menu-item>
        <el-menu-item style="margin: 0 1.25rem;    font-family: MiSans TC;
    font-weight: 500;" index="4">{{ $t('infoDatasTitle.top_titele_four') }}</el-menu-item>
        <el-menu-item style="margin: 0 1.25rem;    font-family: MiSans TC;
    font-weight: 500;" index="5">{{ $t('infoDatasTitle.top_titele_five') }}</el-menu-item>
      </template>
      <!-- <el-submenu index="2" style="margin: 0 1.25rem; ">
        <template slot="title">{{infoDatas.top_titele_two}}</template>
        <div style="background-color:#ffffff;">
          <el-menu-item style="color: #000;" index="2-1">硬件加密芯</el-menu-item>
          <el-menu-item style="color: #000;" index="2-2">安全管理箱</el-menu-item>
          <el-menu-item style="color: #000;" index="2-3">智能恢复引擎</el-menu-item>
        </div>
      </el-submenu>-->

      <el-menu-item style="margin: 0 1.25rem;" index="3">{{ infoDatas.top_titele_three }}</el-menu-item>
      <el-menu-item style="margin: 0 1.25rem;" index="4">{{ infoDatas.top_titele_four }}</el-menu-item>
      <el-menu-item style="margin: 0 1.25rem;" index="5">{{ infoDatas.top_titele_five }}</el-menu-item>
    </el-menu>
    <div class="right-section" v-if="show">
      <el-dropdown trigger="click" @command="handleLanguageChange">
        <div class="language-selector">
          <img style="width: 1.5rem;height: 1.5rem;border-radius:50%;" :src="$getImageUrl(langIcon)" alt />
          <span>{{ currentLanguage }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="lang in languages" :key="lang.code" :command="lang.code">
            <div style="display: flex; align-items:center; gap: 1.25rem;">
              <img style="width: 1.5rem;height: 1.5rem;border-radius:50%;" :src="$getImageUrl(lang.icon)" alt />
              {{ lang.name }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="shopCar" @click="cartVisible = true">
        <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/gw.png" alt />
      </div> -->
      <div class="cart-icon" @click="cartVisible = true">
        <el-badge :value="goodList.length" class="cart-badge">
          <img src="https://dspro999.s3.ap-east-1.amazonaws.com/gov/assets/images/gw.png" alt="购物车" />
        </el-badge>
      </div>
    </div>
    <DrawerIndex :cartVisible.sync="cartVisible" />
  </div>
</template>
<script>
import { languageList, goods } from "@/api/payment";
import { mapState } from "vuex";
export default {
  name: "HeaderIndex",

  props: {
    isScrolled: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
    // infoDatas: {
    //   type: Object,
    //   default: function() {
    //     return {
    //       key: "value"
    //     };
    //   }
    // }
  },
  computed: {
    ...mapState("language", ["currentLanguage"])
  },
  data() {
    return {
      goodList: [],
      infoDatas: {},
      cartVisible: false,
      currentLanguage: localStorage.getItem("languageName") || "繁體中文",
      languages: [],
      code: localStorage.getItem("language") || "tw",
      activeIndex: localStorage.getItem("activeIndex") || "1",
      activeIndexs: "1",
      currentLanguages: {},
      langIcon: localStorage.getItem("langIcon") || ""
    };
  },
  created() {
    this.activeIndex = localStorage.getItem("activeIndex") || "0";

    // 初始化语言
    const savedCode = localStorage.getItem("language") || "tw";
    if (savedCode !== this.$i18n.locale) {
      this.$i18n.locale = savedCode;
    }
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      deep: true,
      handler(newLocale) {
        // 如果 languages 为空，等待数据加载完成
        if (this.languages.length === 0) {
          this.languageListData().then(() => {
            this.updateLanguageConfig(newLocale);
          });
        } else {
          this.updateLanguageConfig(newLocale);
        }
      }
    }
  },
  mounted() {
    this.languageListData();
    this.getGoods()
    // this.topIndexData();
  },
  methods: {
    updateLanguageConfig(newLocale) {
      const currentLang = this.languages.find(lang => {
        return lang.code === newLocale;
      });

      if (currentLang) {
        this.code = newLocale;
        this.currentLanguage = currentLang.name;
        this.langIcon = currentLang.icon;

        localStorage.setItem("language", newLocale);
        localStorage.setItem("languageName", currentLang.name);
        localStorage.setItem("langIcon", currentLang.icon);
      }

      this.$store.dispatch("languages/updateLanguage", {
        code: newLocale,
        name: this.currentLanguage
      });
    },
    languageListData() {
      return languageList()
        .then(res => {
          this.languages = res;
          const currentCode = localStorage.getItem("language") || "tw";
          const currentLang = res.find(lang => lang.code === currentCode);
          if (currentLang) {
            this.langIcon = currentLang.icon;
            localStorage.setItem("langIcon", currentLang.icon);
          } else {
            this.langIcon = res[0].icon;
            localStorage.setItem("langIcon", res[0].icon);
          }
        })
        .catch(err => {
          console.error("获取语言列表失败:", err);
        });
    },
    // topIndexData() {
    //   topIndex({ language: this.code }).then(res => {
    //     this.infoDatas = res;
    //   });
    // },
    // languageListData() {
    //   languageList().then(res => {
    //     this.languages = res;
    //     // 获取当前选中的语言代码
    //     const currentCode = localStorage.getItem("language") || "tw";
    //     // 找到对应的语言配置
    //     const currentLang = res.find(lang => lang.code === currentCode);
    //     // 设置对应的图标
    //     if (currentLang) {
    //       this.langIcon = currentLang.icon;
    //       localStorage.setItem("langIcon", currentLang.icon);
    //     } else {
    //       this.langIcon = res[0].icon;
    //       localStorage.setItem("langIcon", res[0].icon);
    //     }
    //   });
    // },
    handleSelect(key) {
      this.activeIndexs = key;
      localStorage.setItem("activeIndex", key);
      if (key == 3 && this.$route.path !== "/down") {
        this.$router.push("/down");
      }
      if (key == 5 && this.$route.path !== "/about") {
        this.$router.push("/about");
      }
      if (key == 4 && this.$route.path !== "/guide") {
        this.$router.push("/guide");
      }
      if (key == 1 && this.$route.path !== "/") {
        this.$router.push("/");
      }
      if (key == 2 && this.$route.path !== "/product") {
        this.$router.push("/product");
      }
    },
    getGoods() {
      goods({
        machine_code: this.$store.state.device.fingerprint,
        sceneval: 1,
        language: this.code
      }).then(res => {
        this.goodList = res;
      });
    },
    goToHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
        localStorage.setItem("activeIndex", "1");
      }
    },
    oneClickPro() {
      this.$router.push("/cart");
    },
    goToRegister() {
      this.$router.push("/register");
    },
    goToProducts(type) {
      this.$router.push(
        { path: "/products" },
        () => { },
        () => { }
      );
      this.$store.commit("down/UPDATE_DATA", type);
    },
    oneKeyClassic() {
      this.$router.push("/orderIndex");
    },
    handleLanguageChange(code) {
      // 先获取语言配置
      const currentLang = this.languages.find(lang => lang.code === code);
      if (!currentLang) return;

      // 先更新数据
      this.code = code;
      this.currentLanguage = currentLang.name;
      this.langIcon = currentLang.icon;

      // 更新 localStorage
      localStorage.setItem("language", code);
      localStorage.setItem("languageName", currentLang.name);
      localStorage.setItem("langIcon", currentLang.icon);

      // 更新 i18n 和 store
      this.$i18n.locale = code;
      this.$store.dispatch("languages/updateLanguage", {
        code: code,
        name: currentLang.name
      });
      this.$emit("languageChange", code);
      // 更新顶部菜单数据
      // this.topIndexData();
    }
    // translateData() {
    //     translate({ language: this.code }).then(res => {
    //         this.infoDatas = res;
    //     });
    // }
  }
};
</script>
<style scoped lang="scss">
.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.875rem 3.6875rem;
  // width: 100%;
}

.logoLeft {
  width: 100%;
  height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  .logoImg {
    width: 100%;
    height: 100%;
  }

  .logoText {
    font-family: Archivo Black, Archivo Black;
    font-weight: 600;
    font-size: 1.125rem;
    color: #ffffff;
  }
}

.logoCenter {
  display: flex;
  align-items: center;
  gap: 3.75rem;
}

.nav-items {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.el-icon--right {
  display: none;
  // margin-left: .25rem;
  // transition: transform 0.3s;
}

// .el-dropdown:hover .el-icon--right {
//   transform: rotate(180deg);
// }

.menu-section {
  padding: 1rem;
}

.menu-title {
  font-size: 0.875rem;
  color: #909399;
  margin-bottom: 1rem;
  font-weight: 500;
}

.menu-content {
  min-width: 12.5rem;
}

.menu-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.group-title {
  font-size: 0.8125rem;
  color: #909399;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.menu-link {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  color: #303133;
  text-decoration: none;
  font-size: 0.875rem;
  transition: all 0.3s;
}

.menu-link:hover {
  background-color: #f5f7fa;
  color: #409eff;
}

.el-dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border: none;
}

.el-dropdown-item {
  padding: 0;
  margin: 0;
}

.menu-link i {
  font-size: 1rem;
}

.product-dropdown {
  min-width: 50rem;
  padding: 1.5rem;
  z-index: 100000;
}

.menu-section {
  display: flex;
}

.menu-title {
  font-size: 0.875rem;
  color: #909399;
  margin-bottom: 1rem;
  font-weight: 500;
}

.menu-item {
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #f5f7fa;
}

.menu-links {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.5rem;
}

.menu-links a {
  color: #303133;
  text-decoration: none;
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  transition: all 0.3s;
}

.menu-links a:hover {
  background-color: #f5f7fa;
  color: #409eff;
}

.el-dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border: none;
}

.el-dropdown-item {
  padding: 0;
  margin: 0;
}

.el-dropdown-item a {
  display: block;
  text-decoration: none;
  color: inherit;
}

.el-icon--right {
  font-size: 0.75rem;
  margin-left: 0.25rem;
  transition: transform 0.3s;
}

.el-dropdown:hover .el-icon--right {
  transform: rotate(180deg);
}

.el-dropdown-menu__item--divided {
  border-top: 0.0625rem solid #ebeef5;
  margin: 0.375rem 0;
}

.card-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0.5rem;
  color: #303133;
  flex-shrink: 0;
}

.card-content {
  flex: 1;
}

.card-content h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #101111;
  margin-bottom: 0.5rem;
}

.card-content p {
  font-size: 0.875rem;
  color: #909399;
  line-height: 1.6;
}

.item-header {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.0625rem;
  color: #8a9195;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.item-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.item-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #2d3133;
  text-decoration: none;
  transition: color 0.2s;
}

.item-icon {
  width: 1.375rem;
  height: 1.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-text {
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.0625rem;
  color: #101111;
  font-weight: 700;
  padding: 0.75rem 0;
  text-decoration: blanchedalmond;
}

.menu-itemss {
  color: #8a9195;
  font-size: 0.9375rem;
}

.menu-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.5rem;
  font-size: 1.125rem;
  color: #101111;
  font-weight: 700;
  cursor: pointer;
}

::v-deep .el-dropdown-link {
  cursor: pointer;
  // color: #303133;
  // font-size: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: Microsoft YaHei UI, Microsoft YaHei UI;
  font-weight: 400;
  font-size: 1.125rem;
  color: #ffffff;
}

::v-deep .el-dropdown-linkY {
  cursor: pointer;
  // color: #303133;
  // font-size: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: PingFang SC;
  font-weight: 400;
  font-size: 1rem;
  color: #81a9ff;
}

.header-container {
  position: fixed;
  // border-radius: 1.875rem;
  z-index: 2000;
  width: 100%;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 1.25rem 0rem;
  top: 0;
  margin-right: 1.25rem;
}

.language-item {
  font-size: 1.125rem;
  font-weight: 700;
}

.hb-count {
  position: absolute;
  top: -0.25rem;
  right: -0.4375rem;
  background: rgb(240, 31, 17);
  width: 1.25rem;
  height: 1.25rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  color: rgb(255, 255, 255);
  border-radius: 6.1875rem;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-img {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
  margin-right: 1rem;
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.product-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0 0.625rem 0;
}

.product-name {
  font-size: 1.25rem;
  color: #6d777c;
  font-weight: 700;
}

.product-price {
  font-size: 1.125rem;
  color: #909399;
}

.button-content {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ffffff;
  border-radius: 6.25rem;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  color: #000;
}

// .button-content:hover {
//   color: #ffffff;
//   background: #000;
//   opacity: 0.8;
//   transform: translateY(-0.0625rem);
// }
.css-73ds1h:hover {
  background: #4ff55f;
}

.css-iq32hw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
  padding-left: 2rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 0.1875rem;
}

.download-button {
  display: inline-block;
}

.button-link {
  text-decoration: none;
  display: block;
}

.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.625rem;
  height: 1.625rem;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel-item {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  position: relative;
}

.css-10w5gd4 {
  width: 100%;
  height: 100%;
  position: relative;
}

.gatsby-image-wrapper {
  width: 100%;
  height: 100%;
}

.gatsby-image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-badge {
  background: #16d629;
  color: #000;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.125rem 0.375rem;
  border-radius: 6.25rem;
  margin-left: 0.25rem;
}

@media (max-width: 768px) {
  .button-content {
    padding: 0.375rem 0.75rem;
  }

  .button-text {
    font-size: 0.8125rem;
  }

  .button-badge {
    font-size: 0.6875rem;
    padding: 0.0625rem 0.3125rem;
  }
}

.language-selector {
  position: relative;
}

.help-section {
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.75rem rgba(0, 0, 0, 0.04);
}

.help-container {
  max-width: 50rem;
  margin: 0 auto;
}

.help-title {
  margin-bottom: 2rem;
}

.title-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  border-radius: 0.75rem;
  margin-bottom: 1rem;
  color: #303133;
}

.help-title h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #303133;
  margin-bottom: 0.5rem;
}

.help-title p {
  font-size: 0.875rem;
  color: #909399;
  line-height: 1.6;
}

.help-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));
  gap: 1rem;
}

.help-card {
  padding: 1.5rem;
  background: #f5f7fa;
  border-radius: 0.75rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.help-card:hover {
  background: #ebeef5;
  transform: translateY(-0.125rem);
}

@media (max-width: 768px) {
  .help-section {
    padding: 1.5rem;
  }

  .help-cards {
    grid-template-columns: 1fr;
  }

  .help-card {
    padding: 1.25rem;
  }
}

.help-container {
  padding: 2.5rem 1.5rem;
  background: #fff;
}

.help-grid {
  max-width: 75rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.75rem;
}

.help-column {
  display: flex;
  flex-direction: column;
}

.column-title {
  font-size: 0.875rem;
  font-weight: 500;
  color: #909399;
  margin-bottom: 1.25rem;
}

.link-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.link-item {
  font-size: 0.875rem;
  color: #303133;
  text-decoration: none;
  transition: color 0.2s ease;
}

.help-section {
  background: #fff;
  padding: 2.5rem 1.5rem;
}

.help-grid {
  max-width: 75rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5rem, 1fr));
  gap: 2.5rem;
}

.help-item {
  display: flex;
  flex-direction: column;
}

.shopCar {
  position: relative;
  cursor: pointer;
  background: #81a9ff;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5625rem;
    height: 1.8125rem;
  }
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0.125rem solid #3071fc;
  color: #ffffff;
}

::v-deep .el-menu--horizontal>.el-menu-item:hover,
::v-deep .el-menu--horizontal>.el-submenu:hover .el-submenu__title {
  background-color: transparent !important;
}

::v-deep .el-submenu__title {
  font-size: 1rem;
}

::v-deep .el-menu--horizontal>.el-menu-item:focus,
::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:focus {
  background-color: transparent !important;
}

::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  color: #ffffff;
}

::v-deep .el-menu--horizontal .el-menu .el-menu-item {
  color: #ffffff;
}

.nav-menu {
  display: flex;
  align-items: center;
  border: none;
  // padding: 0 50px;
  margin: 0 auto;

  &.el-menu--horizontal {
    border-bottom: none;

    >.el-menu-item {
      // height: 80px;
      // line-height: 80px;
      font-size: 1.25rem;
      padding: 0;

      .is-active {
        color: #ffffff;
      }

      // &:hover,
      // &:focus {
      //   background-color: red;
      // }
    }
  }
}

.logo-container {
  display: flex;
  align-items: center;
  // margin-right: 50px;
  cursor: pointer;
  margin-left: 3.6875rem;

  .logo-img {
    width: 12.375rem;
    height: 2rem;
    margin-right: 0.625rem;
  }

  .logo-text {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

.right-section {
  margin-right: 3.6875rem;
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.language-selector {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
  padding: 0.5rem 0.9375rem;
  border-radius: 1.25rem;
  border: 0.0625rem solid #81a9ff;
  color: #81a9ff;
  cursor: pointer;

  i {
    font-size: 1rem;
  }
}

.cart-icon {
  position: relative;
  cursor: pointer;
  background: #81a9ff;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 1.5625rem;
    height: 1.8125rem;
  }
}

.cart-badge {
  ::v-deep .el-badge__content {
    background-color: #f01f11;
  }
}
</style>
