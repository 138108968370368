import Vue from 'vue'
import VueI18n from 'vue-i18n'
import tw from './langs/tw'
import en from './langs/en'
// import cn from './langs/cn'

Vue.use(VueI18n)

const messages = {
  tw,
  en,
  // cn
}

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'tw', // 设置默认语言
  fallbackLocale: 'tw', // 设置备用语言
  silentTranslationWarn: true, // 关闭翻译警告
  messages
})

export default i18n