(function (win, doc) {
    var docEl = doc.documentElement

    function setRemUnit() {
        var docWidth = docEl.clientWidth
        const baseSize = docWidth < 768 ? 37.5 : 16

        // if (docWidth > 640) {
        // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。

        const ws = docWidth < 768 ? 375 : 2560

        const scale = document.documentElement.clientWidth / ws

        document.documentElement.style.fontSize = baseSize * Math.min(scale, 1) + 'px'
        document.body.style.overflow = 'auto'
        // }
        //  else {
        //     var rem = docWidth / 10
        //     docEl.style.fontSize = rem + 'px'
        // }

    }

    win.addEventListener('resize', function () {
        setRemUnit()
    }, false)
    win.addEventListener('pageshow', function (e) {
        if (e.persisted) {
            setRemUnit()
        }
    }, false)
    setRemUnit()
    win.addEventListener('load', function () {
        document.body.style.overflow = 'auto'
    }, false)
    if (win.devicePixelRatio && win.devicePixelRatio >= 2) {
        var testEl = doc.createElement('div')
        var fakeBody = doc.createElement('body')
        testEl.style.border = '0.5px solid transparent'
        fakeBody.appendChild(testEl)
        docEl.appendChild(fakeBody)
        if (testEl.offsetHeight === 1) {
            docEl.classList.add('hairlines')
        }
        docEl.removeChild(fakeBody)
    }
})(window, document)