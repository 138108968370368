
const state = {
    currentLanguage: localStorage.getItem('language') || 'tw',
    currentLanguageName: localStorage.getItem('languageName') || '繁體中文'
}
const mutations = {
    SET_LANGUAGE(state, { code, name }) {
        state.currentLanguage = code;
        state.currentLanguageName = name;
        localStorage.setItem('language', code);
        localStorage.setItem('languageName', name);
    }
}
const actions = {
    updateLanguage({ commit }, { code, name }) {
        console.log('updateLanguage',code,name);
        
        commit('SET_LANGUAGE', { code, name });
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}